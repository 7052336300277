<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route,
            }"
          />
          <span class="text-h6 ml-1" v-text="`${$route.meta.title}`" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>
        <v-col cols="12">
          <v-alert :type="last_amount ? 'info' : 'warning'" dense>
            <v-row align="center">
              <v-col class="grow">
                <div
                  class="text-center"
                  v-text="
                    last_amount
                      ? `Último monto autorizado registrado ${numberFormat(
                          last_amount.authorized_amount
                        )}`
                      : 'El ingreso hospitalario no cuenta con monto autorizado, la documentación pasara a un proceso de revisión'
                  "
                />
              </v-col>
              <v-col class="shrink">
                <v-tooltip bottom v-if="user_view && last_amount">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      dark
                      @click.prevent="amountDialog(last_amount.id)"
                    >
                      <v-icon v-text="'mdi-file-eye'" />
                    </v-btn>
                  </template>
                  <span v-text="'Ver monto autorizado'" />
                </v-tooltip>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card teal :loading="stepper_loading" :disabled="stepper_loading">
            <v-card-text>
              <v-stepper v-model="step" flat vertical>
                <v-stepper-step :complete="step > 1" step="1">
                  Documentos
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-form
                    v-on:submit.prevent
                    ref="form_documents"
                    lazy-validation
                  >
                    <v-row>
                      <v-col cols="12" xs="12" md="12">
                        <div v-if="!store_mode">
                          <span
                            class="text-caption"
                            v-text="'Identificación (pdf)'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              !data.url_credential ? 'warning' : 'success'
                            "
                            v-text="
                              !data.url_credential ? 'mdi-alert' : 'mdi-check'
                            "
                          />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                :color="
                                  !data.edit_url_credential ? 'info' : 'default'
                                "
                                small
                                v-on="on"
                                @click.prevent="
                                  editVal('edit_url_credential', null, null)
                                "
                              >
                                <v-icon small v-text="'mdi-pencil'" />
                              </v-btn>
                            </template>
                            <span
                              v-text="
                                !data.edit_url_credential
                                  ? 'Editar'
                                  : 'Cancelar edición'
                              "
                            />
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="`${url_documents}/rha_bill_statuses/${data.url_credential}`"
                                target="_blank"
                              >
                                <v-icon small v-text="'mdi-eye'" />
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </div>
                        <v-file-input
                          v-if="
                            store_mode ||
                            (!store_mode && data.edit_url_credential)
                          "
                          v-model="data.document_credential_0"
                          :label="store_mode ? 'Identificación (pdf)*' : ''"
                          accept=".pdf"
                          show-size
                          :rules="rules.fileLmtReq"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      dense
                      v-for="(document, i) in data.documents"
                      :key="i"
                      v-if="document.active"
                    >
                      <v-col cols="12">
                        <span v-text="`Factura ${i + 1}`" />
                      </v-col>
                      <v-col cols="12" xs="12" :md="store_mode ? 11 : 12">
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <div v-if="!store_mode">
                              <span
                                class="text-caption"
                                v-text="'Factura (pdf)'"
                              />
                              <v-icon
                                right
                                small
                                :color="
                                  !document.url_bill ? 'warning' : 'success'
                                "
                                v-text="
                                  !document.url_bill ? 'mdi-alert' : 'mdi-check'
                                "
                              />
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    :color="
                                      !document.edit_url_bill
                                        ? 'info'
                                        : 'default'
                                    "
                                    small
                                    v-on="on"
                                    @click.prevent="
                                      editVal('edit_url_bill', i, null)
                                    "
                                  >
                                    <v-icon small v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    !document.edit_url_bill
                                      ? 'Editar'
                                      : 'Cancelar edición'
                                  "
                                />
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    :href="`${url_documents}/rha_bill_statuses/${document.url_bill}`"
                                    target="_blank"
                                  >
                                    <v-icon small v-text="'mdi-eye'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                            </div>
                            <v-file-input
                              v-if="
                                store_mode ||
                                (!store_mode && document.edit_url_bill)
                              "
                              v-model="data['document_bill_' + i]"
                              :label="store_mode ? 'Factura (pdf)*' : ''"
                              accept=".pdf"
                              show-size
                              dense
                              :rules="rules.fileLmtReq"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <div v-if="!store_mode">
                              <span
                                class="text-caption"
                                v-text="'Factura (xml)'"
                              />
                              <v-icon
                                right
                                small
                                :color="
                                  !document.url_bill_xml ? 'warning' : 'success'
                                "
                                v-text="
                                  !document.url_bill_xml
                                    ? 'mdi-alert'
                                    : 'mdi-check'
                                "
                              />
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    :color="
                                      !document.edit_url_bill_xml
                                        ? 'info'
                                        : 'default'
                                    "
                                    small
                                    v-on="on"
                                    @click.prevent="
                                      editVal('edit_url_bill_xml', i, null)
                                    "
                                  >
                                    <v-icon small v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    !document.edit_url_bill_xml
                                      ? 'Editar'
                                      : 'Cancelar edición'
                                  "
                                />
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    :href="`${url_documents}/rha_bill_statuses/${document.url_bill_xml}`"
                                    target="_blank"
                                  >
                                    <v-icon small v-text="'mdi-eye'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                            </div>
                            <v-file-input
                              v-if="
                                store_mode ||
                                (!store_mode && document.edit_url_bill_xml)
                              "
                              v-model="data['document_bill_xml_' + i]"
                              :label="store_mode ? 'Factura (xml)*' : ''"
                              accept=".xml"
                              show-size
                              dense
                              :rules="rules.fileLmtReq"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <div v-if="!store_mode">
                              <span
                                class="text-caption"
                                v-text="
                                  'Formato edo. cuenta (pdf, xls, xlsx, txt)'
                                "
                              />
                              <v-icon
                                right
                                small
                                :color="
                                  !document.status_account_valid &&
                                  data.status_account_format_id
                                    ? 'warning'
                                    : 'success'
                                "
                                v-text="
                                  !document.status_account_valid &&
                                  data.status_account_format_id
                                    ? 'mdi-alert'
                                    : 'mdi-check'
                                "
                              />
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    :color="
                                      !document.edit_url_status_account
                                        ? 'info'
                                        : 'default'
                                    "
                                    small
                                    v-on="on"
                                    @click.prevent="
                                      editVal(
                                        'edit_url_status_account',
                                        i,
                                        null
                                      )
                                    "
                                  >
                                    <v-icon small v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    !document.edit_url_status_account
                                      ? 'Editar'
                                      : 'Cancelar edición'
                                  "
                                />
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    :href="`${url_documents}/rha_bill_statuses/${document.url_status_account}`"
                                    target="_blank"
                                  >
                                    <v-icon small v-text="'mdi-eye'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                            </div>
                            <v-file-input
                              v-if="
                                store_mode ||
                                (!store_mode &&
                                  document.edit_url_status_account)
                              "
                              v-model="data['document_status_account_' + i]"
                              :label="
                                store_mode
                                  ? 'Formato edo. cuenta (pdf, xls, xlsx, txt)*'
                                  : ''
                              "
                              accept=".pdf,.xls,.xlsx,.txt"
                              show-size
                              dense
                              :rules="rules.fileLmtReq"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <div v-if="!store_mode">
                              <span
                                class="text-caption"
                                v-text="
                                  'Edo. cuenta monto escrito con IVA' +
                                  (document.edit_amount_typed
                                    ? ''
                                    : `: ${numberFormat(
                                        document.amount_typed
                                      )}`)
                                "
                              />
                              <v-icon
                                right
                                small
                                :color="
                                  !document.status_account_valid &&
                                  data.status_account_format_id
                                    ? 'warning'
                                    : 'success'
                                "
                                v-text="
                                  !document.status_account_valid &&
                                  data.status_account_format_id
                                    ? 'mdi-alert'
                                    : 'mdi-check'
                                "
                              />
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    :color="
                                      !document.edit_amount_typed
                                        ? 'info'
                                        : 'default'
                                    "
                                    small
                                    v-on="on"
                                    @click.prevent="
                                      editVal('edit_amount_typed', i, null)
                                    "
                                  >
                                    <v-icon small v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    !document.edit_amount_typed
                                      ? 'Editar'
                                      : 'Cancelar edición'
                                  "
                                />
                              </v-tooltip>
                            </div>
                            <v-text-field
                              v-if="
                                store_mode ||
                                (!store_mode && document.edit_amount_typed)
                              "
                              :label="
                                store_mode
                                  ? 'Edo. cuenta monto escrito con IVA*'
                                  : ''
                              "
                              v-model="document.amount_typed"
                              type="number"
                              dense
                              :rules="rules.required"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="4">
                            <div v-if="!store_mode">
                              <span
                                class="text-caption"
                                v-text="'Carta autorización (pdf)'"
                              />
                              <v-icon
                                right
                                small
                                :color="
                                  !document.url_letter_auth
                                    ? 'warning'
                                    : 'success'
                                "
                                v-text="
                                  !document.url_letter_auth
                                    ? 'mdi-alert'
                                    : 'mdi-check'
                                "
                              />
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    :color="
                                      !document.edit_url_letter_auth
                                        ? 'info'
                                        : 'default'
                                    "
                                    small
                                    v-on="on"
                                    @click.prevent="
                                      editVal('edit_url_letter_auth', i, null)
                                    "
                                  >
                                    <v-icon small v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    !document.edit_url_letter_auth
                                      ? 'Editar'
                                      : 'Cancelar edición'
                                  "
                                />
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    :href="`${url_documents}/rha_bill_statuses/${document.url_letter_auth}`"
                                    target="_blank"
                                  >
                                    <v-icon small v-text="'mdi-eye'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                            </div>
                            <v-file-input
                              v-if="
                                store_mode ||
                                (!store_mode && document.edit_url_letter_auth)
                              "
                              v-model="data['document_letter_auth_' + i]"
                              :label="
                                store_mode ? 'Carta autorización (pdf)*' : ''
                              "
                              accept=".pdf"
                              show-size
                              dense
                              :rules="rules.fileLmtReq"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <div v-if="!store_mode">
                              <span
                                class="text-caption"
                                v-text="
                                  'Otros (xls, xlsx, txt, pdf, doc, docx)'
                                "
                              />
                              <v-icon
                                right
                                small
                                v-text="
                                  !document.url_other
                                    ? 'mdi-alert'
                                    : 'mdi-check'
                                "
                              />
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    :color="
                                      !document.edit_url_other
                                        ? 'info'
                                        : 'default'
                                    "
                                    small
                                    v-on="on"
                                    @click.prevent="
                                      editVal('edit_url_other', i, null)
                                    "
                                  >
                                    <v-icon small v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    !document.edit_url_other
                                      ? 'Editar'
                                      : 'Cancelar edición'
                                  "
                                />
                              </v-tooltip>
                              <v-tooltip bottom v-if="document.url_other">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    small
                                    v-on="on"
                                    :href="`${url_documents}/rha_bill_statuses/${document.url_other}`"
                                    target="_blank"
                                  >
                                    <v-icon small v-text="'mdi-eye'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                            </div>
                            <v-file-input
                              v-if="
                                store_mode ||
                                (!store_mode && document.edit_url_other)
                              "
                              v-model="data['document_other_' + i]"
                              :label="
                                store_mode
                                  ? 'Otros (xls, xlsx, txt, pdf, doc, docx)'
                                  : ''
                              "
                              accept=".xls,.xlsx,.txt,.pdf,.doc,.docx"
                              show-size
                              dense
                              :rules="rules.fileLmt"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                            <div v-if="!store_mode">
                              <span
                                class="text-caption"
                                v-text="
                                  'Descripción' +
                                  (document.edit_other_description
                                    ? ''
                                    : `: ${
                                        document.other_description
                                          ? document.other_description
                                          : ''
                                      }`)
                                "
                              />
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    :color="
                                      !document.edit_other_description
                                        ? 'info'
                                        : 'default'
                                    "
                                    small
                                    v-on="on"
                                    @click.prevent="
                                      editVal('edit_other_description', i, null)
                                    "
                                  >
                                    <v-icon small v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span
                                  v-text="
                                    !document.edit_other_description
                                      ? 'Editar'
                                      : 'Cancelar edición'
                                  "
                                />
                              </v-tooltip>
                            </div>
                            <v-text-field
                              v-if="
                                store_mode ||
                                (!store_mode && document.edit_other_description)
                              "
                              :label="store_mode ? 'Descripción' : ''"
                              v-model="document.other_description"
                              type="text"
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            v-for="(note, j) in document.notes"
                            :key="j"
                            v-if="note.active"
                          >
                            <v-row dense>
                              <v-col cols="12" class="pb-4">
                                <v-divider />
                              </v-col>
                              <v-col cols="12" sm="12" md="4">
                                <div v-if="!store_mode">
                                  <span
                                    class="text-caption"
                                    v-text="
                                      `N. crédito ${i + 1}.${j + 1} (pdf)`
                                    "
                                  />
                                  <v-icon
                                    right
                                    small
                                    :color="!note.url ? 'warning' : 'success'"
                                    v-text="
                                      !note.url ? 'mdi-alert' : 'mdi-check'
                                    "
                                  />
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        :color="
                                          !note.edit_url ? 'info' : 'default'
                                        "
                                        small
                                        v-on="on"
                                        @click.prevent="
                                          editVal('edit_url', i, j)
                                        "
                                      >
                                        <v-icon small v-text="'mdi-pencil'" />
                                      </v-btn>
                                    </template>
                                    <span
                                      v-text="
                                        !note.edit_url
                                          ? 'Editar'
                                          : 'Cancelar edición'
                                      "
                                    />
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        small
                                        v-on="on"
                                        :href="`${url_documents}/rha_bill_notes/${note.url}`"
                                        target="_blank"
                                      >
                                        <v-icon small v-text="'mdi-eye'" />
                                      </v-btn>
                                    </template>
                                    <span v-text="'Ver'" />
                                  </v-tooltip>
                                </div>
                                <v-file-input
                                  v-if="
                                    store_mode || (!store_mode && note.edit_url)
                                  "
                                  v-model="
                                    data['document_note_pdf_' + j + '_' + i]
                                  "
                                  :label="
                                    store_mode
                                      ? `N. crédito ${i + 1}.${j + 1} (pdf)*`
                                      : ''
                                  "
                                  accept=".pdf"
                                  show-size
                                  dense
                                  :rules="rules.fileLmtReq"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="4">
                                <div v-if="!store_mode">
                                  <span
                                    class="text-caption"
                                    v-text="'N. crédito (xml)'"
                                  />
                                  <v-icon
                                    right
                                    small
                                    :color="
                                      !note.url_xml ? 'warning' : 'success'
                                    "
                                    v-text="
                                      !note.url_xml ? 'mdi-alert' : 'mdi-check'
                                    "
                                  />
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        :color="
                                          !note.edit_url_xml
                                            ? 'info'
                                            : 'default'
                                        "
                                        small
                                        v-on="on"
                                        @click.prevent="
                                          editVal('edit_url_xml', i, j)
                                        "
                                      >
                                        <v-icon small v-text="'mdi-pencil'" />
                                      </v-btn>
                                    </template>
                                    <span
                                      v-text="
                                        !note.edit_url_xml
                                          ? 'Editar'
                                          : 'Cancelar edición'
                                      "
                                    />
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        small
                                        v-on="on"
                                        :href="`${url_documents}/rha_bill_notes/${note.url_xml}`"
                                        target="_blank"
                                      >
                                        <v-icon small v-text="'mdi-eye'" />
                                      </v-btn>
                                    </template>
                                    <span v-text="'Ver'" />
                                  </v-tooltip>
                                </div>
                                <v-file-input
                                  v-if="
                                    store_mode ||
                                    (!store_mode && note.edit_url_xml)
                                  "
                                  v-model="
                                    data['document_note_xml_' + j + '_' + i]
                                  "
                                  :label="store_mode ? 'N. crédito (xml)' : ''"
                                  accept=".xml"
                                  show-size
                                  dense
                                  :rules="rules.fileLmt"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="3">
                                <div v-if="!store_mode">
                                  <span
                                    class="text-caption"
                                    v-text="
                                      'Monto escrito con IVA' +
                                      (note.edit_amount_typed
                                        ? ''
                                        : `: ${numberFormat(
                                            note.amount_typed
                                          )}`)
                                    "
                                  />
                                  <v-icon
                                    right
                                    small
                                    :color="
                                      !note.xml_valid ? 'warning' : 'success'
                                    "
                                    v-text="
                                      !note.xml_valid
                                        ? 'mdi-alert'
                                        : 'mdi-check'
                                    "
                                  />
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        :color="
                                          !note.edit_amount_typed
                                            ? 'info'
                                            : 'default'
                                        "
                                        small
                                        v-on="on"
                                        @click.prevent="
                                          editVal('edit_amount_typed', i, j)
                                        "
                                      >
                                        <v-icon small v-text="'mdi-pencil'" />
                                      </v-btn>
                                    </template>
                                    <span
                                      v-text="
                                        !note.edit_amount_typed
                                          ? 'Editar'
                                          : 'Cancelar edición'
                                      "
                                    />
                                  </v-tooltip>
                                </div>
                                <v-text-field
                                  v-if="
                                    store_mode ||
                                    (!store_mode && note.edit_amount_typed)
                                  "
                                  :label="
                                    store_mode ? 'Monto escrito con IVA*' : ''
                                  "
                                  v-model="note.amount_typed"
                                  type="number"
                                  dense
                                  :rules="rules.required"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                xs="12"
                                md="1"
                                class="text-center"
                                align-self="center"
                                v-if="store_mode"
                              >
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      text
                                      icon
                                      x-small
                                      color="error"
                                      @click.prevent="noteRemove(i, j)"
                                    >
                                      <v-icon medium v-text="'mdi-close'" />
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="
                                      `Eliminar n. de crédito (${i + 1}.${
                                        j + 1
                                      })`
                                    "
                                  />
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            v-if="
                              (store_mode && !bill_discount) ||
                              (!store_mode && user_view)
                            "
                          >
                            <v-btn x-small block @click.prevent="noteAdd(i)">
                              <v-icon left v-text="'mdi-file-plus'" />
                              Agregar n. de crédito
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="store_mode ? 1 : 0"
                        class="text-center"
                        align-self="center"
                        v-if="store_mode"
                      >
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click.prevent="billRemove(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar factura (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-if="store_mode || (!store_mode && user_view)"
                    >
                      <v-btn block @click.prevent="billAdd">
                        <v-icon left v-text="'mdi-file-plus'" />
                        Agregar factura
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" v-else> </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-btn
                        block
                        color="primary"
                        :disabled="data.documents.length === 0"
                        @click.prevent="documentationLoad"
                      >
                        Continuar
                        <v-icon right v-text="'mdi-chevron-right'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-step :complete="step > 2" step="2">
                  Lectura
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-row v-if="step == 2">
                    <v-col cols="12">
                      <v-alert v-if="data.bill_repeat" type="error" dense>
                        <div
                          class="text-center"
                          v-text="
                            'Una o varias facturas XML cargadas estan repetidas'
                          "
                        />
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-alert v-if="data.note_repeat" type="error" dense>
                        <div
                          class="text-center"
                          v-text="
                            'Una o varias Notas de crédito XML cargadas estan repetidas'
                          "
                        />
                      </v-alert>
                    </v-col>
                    <v-col
                      cols="12"
                      v-for="(document, i) in data.documents"
                      :key="i"
                    >
                      <v-row dense>
                        <v-col cols="12" class="grey darken-1 white--text">
                          <h3 v-text="`Factura (${i + 1})`" class="pl-1" />
                        </v-col>
                        <v-col cols="12">
                          <v-alert
                            v-if="document.xml && !document.sat_status"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="'Documento XML no valido ante el SAT'"
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && document.bill_exist"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                `El folio hospital de esta factura ya ha sido registrado para el ingreso ${document.bill_exist_rha_folio}`
                              "
                            />
                          </v-alert>
                          <v-alert v-if="!document.xml" type="error" dense>
                            <div
                              class="text-center"
                              v-text="
                                'La factura XML no cuenta con un formato valido de lectura SAT'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              document.xml && !document.status_account_valid
                            "
                            type="info"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El formato del estado de cuenta no concuerda o no es un archivo valido para la lectura del monto con IVA, se tomara en cuenta el Edo. cuenta monto escrito con IVA'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && !document.receiver_code_tax"
                            type="warning"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El RFC del receptor no concuerda con el de Sinergia Médica'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && !document.receiver_name"
                            type="warning"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'La razón social del receptor no concuerda con el de Sinergia Médica'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              document.xml &&
                              document.status_account_valid &&
                              !document.status_account_amount_valid
                            "
                            type="info"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El monto con IVA del formato no es igual al Total del XML, se tomara en cuenta el Edo. cuenta monto escrito con IVA'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              document.xml &&
                              !document.status_account_valid &&
                              !document.amount_typed_valid
                            "
                            type="warning"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El Edo. cuenta monto escrito con IVA no es igual al Total del XML'
                              "
                            />
                          </v-alert>
                        </v-col>
                        <v-col cols="12" v-if="document.xml">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Receptor"
                                :value="document.xml.Receptor.attr.Nombre"
                                :class="
                                  document.receiver_name
                                    ? ''
                                    : 'orange--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="document.xml.Receptor.attr.Rfc"
                                :class="
                                  document.receiver_code_tax
                                    ? ''
                                    : 'orange--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                :class="
                                  document.status_account_valid &&
                                  !document.status_account_amount_valid
                                    ? 'orange--text text--darken-3'
                                    : ''
                                "
                                label="Formato edo. cuenta monto con IVA"
                                :value="
                                  numberFormat(document.status_account_amount)
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                :class="
                                  !document.status_account_valid &&
                                  !document.amount_typed_valid
                                    ? 'orange--text text--darken-3'
                                    : ''
                                "
                                label="Edo. cuenta monto escrito con IVA"
                                :value="numberFormat(document.amount_typed)"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Emisor"
                                :value="document.xml.Emisor.attr.Nombre"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="document.xml.Emisor.attr.Rfc"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Folio"
                                :value="document.xml.attr.Folio"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Fecha y hora de expedición"
                                :value="document.xml.attr.Fecha"
                              />
                            </v-col>
                            <v-col cols="12">
                              <span
                                class="text-caption font-weight-bold"
                                v-text="'Conceptos'"
                              />
                              <small>
                                <v-simple-table dense>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th v-text="'#'" />
                                        <th v-text="'Unidad'" />
                                        <th v-text="'Cantidad'" />
                                        <th v-text="'Clave'" />
                                        <th v-text="'Descripción'" />
                                        <th v-text="'Precio'" />
                                        <th v-text="'Importe'" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(concept, j) in document.xml
                                          .Conceptos.Concepto"
                                        :key="j"
                                      >
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="j + 1"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            `${concept.attr.ClaveUnidad} | ${concept.attr.Unidad}`
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Cantidad"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.ClaveProdServ"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Descripcion"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              concept.attr.ValorUnitario
                                            )
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(concept.attr.Importe)
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Subtotal'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.xml.attr.SubTotal
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Descuento'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.xml.attr.Descuento
                                                ? document.xml.attr.Descuento
                                                : 0
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'IVA'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.xml.Impuestos.attr
                                                .TotalImpuestosTrasladados
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Total'"
                                        />
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="
                                            numberFormat(
                                              document.xml.attr.Total
                                            )
                                          "
                                        />
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </small>
                            </v-col>
                            <v-col v-if="document.insured_name_xml" cols="12">
                              <ViewData
                                label="Nombre asegurado en XML"
                                :value="document.insured_name_xml"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" v-if="!data.to_stamp">
                          <v-alert
                            v-if="
                              (!user_view &&
                                !rha.provider.credit_note_after &&
                                data.provider_validation_1 === false) ||
                              (user_view &&
                                data.provider_validation_1 === false)
                            "
                            type="warning"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                `El porcentaje de descuento fijo en la(s) nota(s) de crédito es menor al porcentaje a pagar${
                                  user_view
                                    ? `, el monto mínimo indicado con IVA es ${numberFormat(
                                        data.min_discount_amount
                                      )}`
                                    : ''
                                }`
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              (!user_view &&
                                !rha.provider.credit_note_after &&
                                data.provider_validation_2 === false) ||
                              (user_view &&
                                data.provider_validation_2 === false)
                            "
                            type="warning"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                `El porcentaje de descuento fijo en la(s) factura(s) es menor al porcentaje a pagar${
                                  user_view
                                    ? `, el monto mínimo indicado es ${numberFormat(
                                        data.min_discount_amount
                                      )}`
                                    : ''
                                }`
                              "
                            />
                          </v-alert>
                        </v-col>
                        <v-col
                          cols="12"
                          v-for="(note, k) in document.notes"
                          :key="k"
                        >
                          <v-row dense>
                            <v-col cols="12" class="grey darken-1 white--text">
                              <h3
                                v-text="`N. crédito (${i + 1}.${k + 1})`"
                                class="pl-1"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-alert
                                v-if="note.xml && !note.sat_status"
                                type="error"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="'Documento XML no valido ante SAT'"
                                />
                              </v-alert>
                              <v-alert
                                v-if="note.xml && note.note_exist"
                                type="error"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    `El folio hospital de esta factura ya ha sido registrado para el ingreso ${note.note_exist_rha_folio}`
                                  "
                                />
                              </v-alert>
                              <v-alert v-if="!note.xml" type="info" dense>
                                <div
                                  class="text-center"
                                  v-text="
                                    `No se cargo un archivo XML para la lectura del monto con IVA, se tomara en cuenta el monto escrito con IVA de ${numberFormat(
                                      note.amount_typed
                                    )}`
                                  "
                                />
                              </v-alert>
                              <v-alert
                                v-if="note.xml && !note.receiver_code_tax"
                                type="warning"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    'El RFC del receptor no concuerda con el de Sinergia Médica'
                                  "
                                />
                              </v-alert>
                              <v-alert
                                v-if="note.xml && !note.receiver_name"
                                type="warning"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    'La razón social del receptor no concuerda con el de Sinergia Médica'
                                  "
                                />
                              </v-alert>
                              <v-alert
                                v-if="note.xml && !note.amount_typed_valid"
                                type="warning"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    'El monto escrito con IVA no es igual al del XML'
                                  "
                                />
                              </v-alert>
                            </v-col>
                            <v-col cols="12" v-if="note.xml">
                              <v-row dense>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    :class="
                                      note.receiver_name
                                        ? ''
                                        : 'orange--text text--darken-3'
                                    "
                                    label="Receptor"
                                    :value="note.xml.Receptor.attr.Nombre"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    :class="
                                      note.receiver_code_tax
                                        ? ''
                                        : 'orange--text text--darken-3'
                                    "
                                    label="RFC"
                                    :value="note.xml.Receptor.attr.Rfc"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    :class="
                                      !note.amount_typed_valid
                                        ? 'orange--text text--darken-3'
                                        : ''
                                    "
                                    label="Monto escrito con IVA"
                                    :value="numberFormat(note.amount_typed)"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3" />
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="Emisor"
                                    :value="note.xml.Emisor.attr.Nombre"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="RFC"
                                    :value="note.xml.Emisor.attr.Rfc"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="Folio"
                                    :value="note.xml.attr.Folio"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="Fecha y hora de expedición"
                                    :value="note.xml.attr.Fecha"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <span
                                    class="text-caption font-weight-bold"
                                    v-text="`Conceptos`"
                                  />
                                  <small>
                                    <v-simple-table dense>
                                      <template v-slot:default>
                                        <thead>
                                          <tr>
                                            <th v-text="'#'" />
                                            <th v-text="'Unidad'" />
                                            <th v-text="'Cantidad'" />
                                            <th v-text="'Clave'" />
                                            <th v-text="'Descripción'" />
                                            <th v-text="'Precio'" />
                                            <th v-text="'Importe'" />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(concept, l) in note.xml
                                              .Conceptos.Concepto"
                                            :key="l"
                                          >
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="l + 1"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                `${concept.attr.ClaveUnidad} | ${concept.attr.Unidad}`
                                              "
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="concept.attr.Cantidad"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                concept.attr.ClaveProdServ
                                              "
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="concept.attr.Descripcion"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                numberFormat(
                                                  concept.attr.ValorUnitario
                                                )
                                              "
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                numberFormat(
                                                  concept.attr.Importe
                                                )
                                              "
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'Subtotal'"
                                            />
                                            <td
                                              v-text="
                                                numberFormat(
                                                  note.xml.attr.SubTotal
                                                )
                                              "
                                              class="text-caption"
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'Descuento'"
                                            />
                                            <td
                                              v-text="
                                                numberFormat(
                                                  note.xml.attr.Descuento
                                                    ? note.xml.attr.Descuento
                                                    : 0
                                                )
                                              "
                                              class="text-caption"
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'IVA'"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                numberFormat(
                                                  note.xml.Impuestos.attr
                                                    .TotalImpuestosTrasladados
                                                )
                                              "
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'Total'"
                                            />
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="
                                                numberFormat(
                                                  note.xml.attr.Total
                                                )
                                              "
                                            />
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </small>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <v-btn block @click.prevent="documentationReturn">
                        <v-icon left v-text="'mdi-chevron-left'" />
                        Atrás
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-btn
                        block
                        :color="data.to_step_3 ? 'primary' : ''"
                        @click.prevent="documentationVerify"
                      >
                        Continuar
                        <v-icon right v-text="'mdi-chevron-right'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-step :complete="step > 3" step="3">
                  Cargar documentación
                </v-stepper-step>
                <v-stepper-content step="3">
                  <v-row v-if="step == 3">
                    <v-col cols="12" v-if="!data.to_stamp">
                      <v-alert type="warning" dense>
                        <div
                          class="text-center"
                          v-text="
                            'La documentación a cargar pasara a un proceso de revisión'
                          "
                        />
                        <div
                          v-if="!data.valid_receiver_code_taxs"
                          class="text-center"
                          v-text="
                            '*El RFC del receptor de una o varias facturas no es correcta'
                          "
                        />
                        <div
                          v-if="!data.valid_receiver_names"
                          class="text-center"
                          v-text="
                            '*La razón social del receptor de una o varias facturas no es correcta'
                          "
                        />
                        <div
                          v-if="!data.valid_amounts"
                          class="text-center"
                          v-text="
                            '*El monto del formato o el ingresado no concuerda con el total de una o varias facturas'
                          "
                        />
                        <div
                          v-if="data.authorized_amount === null"
                          class="text-center"
                          v-text="
                            '*El ingreso hospitalario no cuenta con monto autorizado'
                          "
                        />
                        <div
                          v-if="data.authorized_amount === false"
                          class="text-center"
                          v-text="
                            '*El edo. cuenta monto con IVA total supera al monto autorizado'
                          "
                        />
                        <div
                          v-if="
                            (!user_view &&
                              !rha.provider.credit_note_after &&
                              data.provider_validation_1 === false) ||
                            (user_view && data.provider_validation_1 === false)
                          "
                          class="text-center"
                          v-text="
                            `*El porcentaje de descuento fijo en la(s) nota(s) de crédito es menor al porcentaje a pagar${
                              user_view
                                ? `, el monto mínimo indicado con IVA es ${numberFormat(
                                    data.min_discount_amount
                                  )}`
                                : ''
                            }`
                          "
                        />
                        <div
                          v-if="
                            (!user_view &&
                              !rha.provider.credit_note_after &&
                              data.provider_validation_2 === false) ||
                            (user_view && data.provider_validation_2 === false)
                          "
                          class="text-center"
                          v-text="
                            `*El porcentaje de descuento fijo en la(s) factura(s) es menor al porcentaje a pagar${
                              user_view
                                ? `, el monto mínimo indicado con IVA es ${numberFormat(
                                    data.min_discount_amount
                                  )}`
                                : ''
                            }`
                          "
                        />
                        <div
                          v-if="data.bills_with_notes === false"
                          class="text-center"
                          v-text="'*No se están integrando notas de crédito'"
                        />
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Identificación'"
                      />
                      <v-icon
                        right
                        small
                        :color="
                          (store_mode && data.document_credential_0) ||
                          (!store_mode && data.url_credential)
                            ? 'success'
                            : 'warning'
                        "
                        v-text="
                          (store_mode && data.document_credential_0) ||
                          (!store_mode && data.url_credential)
                            ? 'mdi-check'
                            : 'mdi-alert'
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total factura(s)'"
                      />
                      <span v-text="numberFormat(data.bills_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total IVA'"
                      />
                      <span v-text="numberFormat(data.bills_tax_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total Nota(s) de Crédito'"
                      />
                      <span v-text="numberFormat(data.notes_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total a pagar'"
                      />
                      <span v-text="numberFormat(data.pay_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Cobro Aseg'"
                      />
                      <span v-text="numberFormat(data.insurance_pay_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Deducible Obs.'"
                      />
                      <span v-text="numberFormat(data.deductibles_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Coaseguro Obs.'"
                      />
                      <span v-text="numberFormat(data.coinsurances_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Desc. Calc Obs.'"
                      />
                      <span v-text="numberFormat(data.discounts_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" v-if="user_view">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'% IVA Calc.'"
                      />
                      <span v-text="data.taxed" />
                    </v-col>
                    <v-col
                      cols="12"
                      v-for="(document, i) in data.documents"
                      :key="i"
                    >
                      <v-row dense>
                        <v-col cols="12" class="grey darken-3 white--text">
                          <h4
                            v-text="`Factura (${i + 1}) | ${document.folio}`"
                            class="pl-1"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura PDF'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              (store_mode && data['document_bill_' + i]) ||
                              (!store_mode && document.url_bill)
                                ? 'success'
                                : 'warning'
                            "
                            v-text="
                              (store_mode && data['document_bill_' + i]) ||
                              (!store_mode && document.url_bill)
                                ? 'mdi-check'
                                : 'mdi-alert'
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura XML'"
                          />
                          <v-icon
                            right
                            small
                            :color="document.xml_valid ? 'success' : 'warning'"
                            v-text="
                              document.xml_valid ? 'mdi-check' : 'mdi-alert'
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'RFC y razon social'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              document.receiver_code_tax &&
                              document.receiver_name
                                ? 'success'
                                : 'warning'
                            "
                            v-text="
                              document.receiver_code_tax &&
                              document.receiver_name
                                ? 'mdi-check'
                                : 'mdi-alert'
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Formato edo. cuenta monto con IVA'"
                          />
                          <span
                            :class="
                              document.status_account_valid === null ||
                              (document.status_account_valid &&
                                document.status_account_amount_valid)
                                ? ''
                                : 'orange--text text--darken-3'
                            "
                            v-text="
                              numberFormat(document.status_account_amount)
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Edo. cuenta monto con IVA'"
                          />
                          <span
                            :class="
                              document.amount_typed_valid
                                ? ''
                                : 'orange--text text--darken-3'
                            "
                            v-text="numberFormat(document.amount_typed)"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Carta autorización'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              (store_mode &&
                                data['document_letter_auth_' + i]) ||
                              (!store_mode && document.url_letter_auth)
                                ? 'success'
                                : 'warning'
                            "
                            v-text="
                              (store_mode &&
                                data['document_letter_auth_' + i]) ||
                              (!store_mode && document.url_letter_auth)
                                ? 'mdi-check'
                                : 'mdi-alert'
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Otros'"
                          />
                          <v-icon
                            right
                            small
                            v-text="
                              data['document_other_' + i]
                                ? 'mdi-check'
                                : 'mdi-alert'
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="5">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Descripción'"
                          />
                          <span
                            class="text-caption"
                            v-text="document.other_description"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Monto XML'"
                          />
                          <span v-text="numberFormat(document.xml_amount)" />
                        </v-col>
                        <v-col
                          cols="12"
                          v-for="(note, j) in document.notes"
                          :key="j"
                        >
                          <v-row dense>
                            <v-col cols="12">
                              <v-divider />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="
                                  `N. crédito PDF(${i + 1}.${j + 1}) ${
                                    note.folio ? `| ${note.folio}` : ''
                                  }`
                                "
                              />

                              <v-icon
                                right
                                small
                                :color="
                                  (store_mode &&
                                    data['document_note_pdf_' + j + '_' + i]) ||
                                  (!store_mode && note.url)
                                    ? 'success'
                                    : 'warning'
                                "
                                v-text="
                                  (store_mode &&
                                    data['document_note_pdf_' + j + '_' + i]) ||
                                  (!store_mode && note.url)
                                    ? 'mdi-check'
                                    : 'mdi-alert'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'N. crédito XML'"
                              />
                              <v-icon
                                right
                                small
                                :color="note.xml_valid ? 'success' : 'default'"
                                v-text="
                                  note.xml_valid ? 'mdi-check' : 'mdi-alert'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'Monto XML'"
                              />
                              <span
                                v-if="note.xml_amount"
                                v-text="numberFormat(note.xml_amount)"
                              />
                              <v-icon
                                v-else
                                right
                                small
                                color="default"
                                v-text="'mdi-alert'"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'Monto escrito con IVA'"
                              />
                              <span
                                :class="
                                  note.amount_typed_valid
                                    ? ''
                                    : 'orange--text text--darken-3'
                                "
                                v-text="numberFormat(note.amount_typed)"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <v-btn block @click.prevent="step = 2">
                        <v-icon left v-text="'mdi-chevron-left'" />
                        Atrás
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-btn
                        block
                        color="success"
                        @click.prevent="documentationSubmit"
                      >
                        Cargar
                        <v-icon right v-text="'mdi-upload'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="amount_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :disabled="amount_dialog_loading"
        :loading="amount_dialog_loading"
        v-if="amount_dialog"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Monto autorizado`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="amount_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="amount">
          <v-form ref="form_amount" lazy-validation>
            <v-row class="pt-3">
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DETALLE'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <ViewData
                          label="Respuesta"
                          :value="
                            amount.rha_amount_response.rha_amount_response
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <ViewData
                          label="Monto"
                          :value="numberFormat(amount.authorized_amount)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-row
                          dense
                          v-for="(document, i) in amount.documents"
                          :key="i"
                          v-if="document.active"
                        >
                          <v-col cols="12" sm="12" md="4">
                            <ViewData
                              :label="`Tipo (${i + 1})`"
                              :value="
                                document.document
                                  ? document.document.document
                                  : ''
                              "
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <ViewData
                              label="Descripción"
                              :value="document.description"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="2">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="ml-1"
                                  v-on="on"
                                  fab
                                  x-small
                                  color="warning"
                                  dark
                                  :href="
                                    url_documents +
                                    '/rha_amounts/' +
                                    document.url
                                  "
                                  target="_blank"
                                >
                                  <v-icon v-text="'mdi-download'" />
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'Números'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData label="Siniestro" :value="amount.sinester" />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Carta autorización"
                          :value="amount.letter_auth"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  rules,
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  msgAlert,
  msgConfirm,
} from "../../control";
import BtnCircle from "../../components/BtnCircle.vue";
import RhaData from "../../components/RhaData.vue";
import ViewData from "../../components/ViewData.vue";
import { show, storeUpdate } from "../../requests/pageRequest";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    RhaData,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "facturacion",
      api: "bills",
      rules: rules(),
      url_documents: URL_DOCUMENTS,
      rha_id: parseInt(this.$route.params.rha_id),
      rha_bill_id: this.$route.params.rha_bill_id
        ? parseInt(this.$route.params.rha_bill_id)
        : null,
      login: this.$store.getters.getLogin,
      user_view: null,
      data: {},
      rha: null,
      last_amount: {},
      loading: true,
      stepper_loading: false,
      step: 1,
      documents_name: [
        "bill",
        "bill_xml",
        "status_account",
        "letter_auth",
        "other",
      ],
      bill_discount: null,
      store_mode: null,
      amount: null,
      amount_dialog: null,
      amount_dialog_loading: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      if (this.store_mode) {
        this.data = {
          id: null,
          active: 1,
          created_by_id: this.login.id,
          updated_by_id: this.login.id,
          rha_id: this.rha_id,
          provider_id: null,
          status_account_format_id: null,
          insurance_id: null,
          document_credential_0: null,
          documents: [],
          //-----------------
          bills_repeat: null,
          notes_repeat: null,
          bills_exist: null,
          notes_exist: null,
          xmls_valid: null,
          provider_validation_1: null,
          notes_subtotal_amount: null,
          bills_subtotal_amount: null,
          provider_validation_2: null,
          bills_discount_amount: null,
          bills_amount: null,
          notes_amount: null,
          pay_amount: null,
          insurance_pay_amount: null,
          discounts_amount: null,
          deductibles_amount: null,
          coinsurances_amount: null,
          valid_amounts: null,
          valid_receiver_code_taxs: null,
          valid_receiver_names: null,
          authorized_amount: null,
          bills_with_notes: null,
          min_discount_amount: null,
          to_step_3: null,
          to_stamp: null,
          store_mode: true,
        };

        this.getDetail();
      } else {
        show("bills/rha", this.login.token, this.rha_bill_id).then(
          (response) => {
            this.data = response;
            this.data.store_mode = false;
            this.getDetail();
          }
        );
      }
    },
    getDetail() {
      show("rha", this.login.token, this.rha_id).then((response) => {
        this.rha = response;

        this.data.provider_id = this.rha.provider_id;
        this.data.status_account_format_id =
          this.rha.provider.status_account_format_id;
        this.bill_discount = this.rha.provider.bill_discount ? true : false;
        this.data.insurance_id = this.rha.insurance_id;

        show("rha/amount/" + this.rha_id + "/last", this.login.token, 1).then(
          (response) => {
            this.last_amount = response;
            this.loading = false;

            if (!this.store_mode) {
              this.data.edit_url_credential = false;
              for (let document of this.data.documents) {
                document.edit_url_bill = false;
                document.edit_url_bill_xml = !document.xml_valid ? true : false;
                document.edit_url_status_account =
                  !document.status_account_valid &&
                  this.data.status_account_format_id
                    ? true
                    : false;
                document.edit_amount_typed =
                  !document.status_account_valid &&
                  this.data.status_account_format_id
                    ? true
                    : false;
                document.edit_url_letter_auth = false;
                document.edit_url_other = false;
                document.edit_other_description = false;

                for (let note of document.notes) {
                  note.edit_url = false;
                  note.edit_url_xml = false;
                  note.edit_amount_typed = !note.xml_amount ? true : false;
                }
              }
            }
          }
        );
      });
    },
    billAdd() {
      for (let i = 0; i < this.documents_name.length; i++) {
        this.data[
          "document_" +
            this.documents_name[i] +
            "_" +
            this.data.documents.length
        ] = null;
      }

      this.data.documents.push({
        id: null,
        active: 1,
        url_bill: null,
        url_bill_xml: null,
        url_status_account: null,
        url_letter_auth: null,
        url_other: null,
        amount_typed: "",
        other_description: null,
        notes: [],
        //-----------------
        xml: null,
        receiver_code_tax: null,
        receiver_name: null,
        sat_status: null,
        bill_exist: null,
        status_account_valid: null,
        status_account_amount_valid: null,
        status_account_amount: null,
        amount_typed_valid: null,
        xml_amount: null,
        folio: null,
        xml_valid: false,
        insured_name_xml: null,
      });
    },
    billRemove(index) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la factura (${index + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            for (let i = 0; i < this.data.documents.length; i++) {
              if (i >= index) {
                for (let j = 0; j < this.documents_name.length; j++) {
                  if (
                    typeof this.data[
                      "document_" + this.documents_name[j] + "_" + (i + 1)
                    ] !== "undefined"
                  ) {
                    this.data["document_" + this.documents_name[j] + "_" + i] =
                      this.data[
                        "document_" + this.documents_name[j] + "_" + (i + 1)
                      ];
                  } else {
                    delete this.data[
                      "document_" + this.documents_name[j] + "_" + i
                    ];
                  }
                }
              }
            }

            if (this.data.documents[index].id === null) {
              this.data.documents.splice(index, 1);
            } else {
              this.data.documents[index].active = false;
            }
          }
        });
    },
    noteAdd(i) {
      this.data[
        "document_note_xml_" + this.data.documents[i].notes.length + "_" + i
      ] = null;
      this.data[
        "document_note_pdf_" + this.data.documents[i].notes.length + "_" + i
      ] = null;

      this.data.documents[i].notes.push({
        id: null,
        active: true,
        url_bill: null,
        url_xml: null,
        amount_typed: null,
        //-----------------
        xml: null,
        receiver_code_tax: null,
        receiver_name: null,
        sat_status: null,
        note_exist: null,
        amount_typed_valid: null,
        xml_amount: null,
        folio: null,
        xml_valid: null,
      });
    },
    noteRemove(index, index2) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma eliminar la n. de crédito (${
              index2 + 1
            }) de la factura (${index + 1})?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            for (let j = 0; j < this.data.documents[index].notes.length; j++) {
              if (j >= index2) {
                if (
                  typeof this.data[
                    "document_note_xml_" + (j + 1) + "_" + index
                  ] !== "undefined"
                ) {
                  this.data["document_note_xml_" + j + "_" + index] =
                    this.data["document_note_xml_" + (j + 1) + "_" + index];

                  this.data["document_note_pdf_" + j + "_" + index] =
                    this.data["document_note_pdf_" + (j + 1) + "_" + index];
                } else {
                  delete this.data["document_note_xml_" + j + "_" + index];
                  delete this.data["document_note_pdf_" + j + "_" + index];
                }
              }
            }

            if (this.data.documents[index].notes[index2].id === null) {
              this.data.documents[index].notes.splice(index2, 1);
            } else {
              this.data.documents[index].notes[index2].active = false;
            }
          }
        });
    },
    documentationLoad() {
      if (this.$refs.form_documents.validate()) {
        this.stepper_loading = true;

        storeUpdate(
          `${this.api}/xml/to/json`,
          this.login.token,
          null,
          this.data,
          this.login.id
        ).then((response) => {
          //console.log(response);
          const resp = response.data;

          this.data.bills_repeat = resp.bills_repeat;
          this.data.notes_repeat = resp.notes_repeat;
          this.data.bills_exist = resp.bills_exist;
          this.data.notes_exist = resp.notes_exist;
          this.data.xmls_valid = resp.xmls_valid;
          this.data.provider_validation_1 = resp.provider_validation_1;
          this.data.notes_subtotal_amount = resp.notes_subtotal_amount;
          this.data.bills_subtotal_amount = resp.bills_subtotal_amount;
          this.data.provider_validation_2 = resp.provider_validation_2;
          this.data.bills_discount_amount = resp.bills_discount_amount;
          this.data.bills_amount = resp.bills_amount;
          this.data.notes_amount = resp.notes_amount;
          this.data.pay_amount = resp.pay_amount;
          this.data.insurance_pay_amount = resp.insurance_pay_amount;
          this.data.discounts_amount = resp.discounts_amount;
          this.data.deductibles_amount = resp.deductibles_amount;
          this.data.coinsurances_amount = resp.coinsurances_amount;
          this.data.valid_amounts = resp.valid_amounts;
          this.data.valid_receiver_code_taxs = resp.valid_receiver_code_taxs;
          this.data.valid_receiver_names = resp.valid_receiver_names;
          this.data.authorized_amount = resp.authorized_amount;
          this.data.bills_with_notes = resp.bills_with_notes;
          this.data.min_discount_amount = resp.min_discount_amount;
          this.data.to_step_3 = resp.to_step_3;
          this.data.to_stamp = resp.to_stamp;
          this.data.bills_tax_amount = resp.bills_tax_amount;
          this.data.taxed = resp.taxed;

          for (let i = 0; i < this.data.documents.length; i++) {
            this.data.documents[i].xml = resp.documents[i].xml;
            this.data.documents[i].receiver_code_tax =
              resp.documents[i].receiver_code_tax;
            this.data.documents[i].receiver_name =
              resp.documents[i].receiver_name;
            this.data.documents[i].bill_exist = resp.documents[i].bill_exist;
            this.data.documents[i].bill_exist_rha_folio =
              resp.documents[i].bill_exist_rha_folio;
            this.data.documents[i].sat_status = resp.documents[i].sat_status;
            this.data.documents[i].status_account_valid =
              resp.documents[i].status_account_valid;
            this.data.documents[i].status_account_amount_valid =
              resp.documents[i].status_account_amount_valid;
            this.data.documents[i].status_account_amount =
              resp.documents[i].status_account_amount;
            this.data.documents[i].amount_typed_valid =
              resp.documents[i].amount_typed_valid;
            this.data.documents[i].xml_amount = resp.documents[i].xml_amount;
            this.data.documents[i].folio = resp.documents[i].folio;
            this.data.documents[i].xml_valid = resp.documents[i].xml_valid;
            this.data.documents[i].notes_amount =
              resp.documents[i].notes_amount;
            this.data.documents[i].bill_tax_amount =
              resp.documents[i].bill_tax_amount;
            this.data.documents[i].date_bill_stamped =
              resp.documents[i].date_bill_stamped;
            this.data.documents[i].insured_name_xml =
              resp.documents[i].insured_name_xml;

            for (let j = 0; j < this.data.documents[i].notes.length; j++) {
              this.data.documents[i].notes[j].xml =
                resp.documents[i].notes[j].xml;
              this.data.documents[i].notes[j].receiver_code_tax =
                resp.documents[i].notes[j].receiver_code_tax;
              this.data.documents[i].notes[j].receiver_name =
                resp.documents[i].notes[j].receiver_name;
              this.data.documents[i].notes[j].sat_status =
                resp.documents[i].notes[j].sat_status;
              this.data.documents[i].notes[j].note_exist =
                resp.documents[i].notes[j].note_exist;
              this.data.documents[i].notes[j].note_exist_rha_folio =
                resp.documents[i].notes[j].note_exist_rha_folio;
              this.data.documents[i].notes[j].amount_typed_valid =
                resp.documents[i].notes[j].amount_typed_valid;
              this.data.documents[i].notes[j].xml_amount =
                resp.documents[i].notes[j].xml_amount;
              this.data.documents[i].notes[j].folio =
                resp.documents[i].notes[j].folio;
              this.data.documents[i].notes[j].xml_valid =
                resp.documents[i].notes[j].xml_valid;
              this.data.documents[i].notes[j].date_bill_stamped =
                resp.documents[i].notes[j].date_bill_stamped;
            }
          }

          this.stepper_loading = false;
          this.step = 2;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    documentationReturn() {
      this.stepper_loading = true;

      this.data.bills_repeat = null;
      this.data.notes_repeat = null;
      this.data.bills_exist = null;
      this.data.notes_exist = null;
      this.data.xmls_valid = null;
      this.data.provider_validation_1 = null;
      this.data.notes_subtotal_amount = null;
      this.data.bills_subtotal_amount = null;
      this.data.provider_validation_2 = null;
      this.data.bills_discount_amount = null;
      this.data.bills_amount = null;
      this.data.notes_amount = null;
      this.data.pay_amount = null;
      this.data.insurance_pay_amount = null;
      this.data.discounts_amount = null;
      this.data.deductibles_amount = null;
      this.data.coinsurances_amount = null;
      this.data.valid_amounts = null;
      this.data.valid_receiver_code_taxs = null;
      this.data.valid_receiver_names = null;
      this.data.authorized_amount = null;
      this.data.bills_with_notes = null;
      this.data.min_discount_amount = null;
      this.data.to_step_3 = null;
      this.data.to_stamp = null;

      for (let i = 0; i < this.data.documents.length; i++) {
        this.data.documents[i].xml = null;
        this.data.documents[i].receiver_code_tax = null;
        this.data.documents[i].receiver_name = null;
        this.data.documents[i].bill_exist = null;
        this.data.documents[i].sat_status = null;
        this.data.documents[i].status_account_valid = null;
        this.data.documents[i].status_account_amount_valid = null;
        this.data.documents[i].status_account_amount = null;
        this.data.documents[i].amount_typed_valid = null;
        this.data.documents[i].xml_amount = null;
        this.data.documents[i].folio = null;
        this.data.documents[i].xml_valid = null;
        this.data.documents[i].notes_amount = null;
        this.data.documents[i].insured_name_xml = null;

        for (let j = 0; j < this.data.documents[i].notes.length; j++) {
          this.data.documents[i].notes[j].xml = null;
          this.data.documents[i].notes[j].receiver_code_tax = null;
          this.data.documents[i].notes[j].receiver_name = null;
          this.data.documents[i].notes[j].sat_status = null;
          this.data.documents[i].notes[j].note_exist = null;
          this.data.documents[i].notes[j].amount_typed_valid = null;
          this.data.documents[i].notes[j].xml_amount = null;
          this.data.documents[i].notes[j].folio = null;
          this.data.documents[i].notes[j].xml_valid = null;
        }
      }

      this.stepper_loading = false;
      this.step = 1;
    },

    documentationVerify() {
      if (this.data.to_step_3) {
        this.step = 3;
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Existen detalles remarcados en rojo que deben ser atendidos para continuar"
          )
        );
      }
    },
    documentationSubmit() {
      if (this.$refs.form_documents.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma cargar la documentación?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.stepper_loading = true;

              storeUpdate(
                `${this.api}/rha`,
                this.login.token,
                null,
                this.data,
                this.login.id
              ).then((response) => {
                //console.log(response);
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  // if (this.id) {
                  //   this.$router.push({
                  //     name: `${this.page_route}.detalle`,
                  //     params: {
                  //       id: this.id,
                  //     },
                  //   });
                  // } else {
                  this.$router.push({
                    name: this.page_route,
                    params: {
                      id: response.data.id,
                      rha_folio: response.data.folio,
                      pay_date: response.data.pay_date,
                      reception_date: response.data.reception_date,
                    },
                  });
                  // }
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      }
    },
    amountDialog(id) {
      this.amount = null;
      this.amount_dialog_loading = true;
      this.amount_dialog = true;
      show("rha/amount", this.login.token, id).then((response) => {
        this.amount = response;
        this.amount_dialog_loading = false;
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    editVal(name, i, j) {
      if (i == null && j == null) {
        this.data[name] = !this.data[name];
      } else {
        if (i >= 0 && j == null) {
          this.data.documents[i][name] = !this.data.documents[i][name];
        } else {
          this.data.documents[i].notes[j][name] =
            !this.data.documents[i].notes[j][name];
        }
      }

      this.$forceUpdate();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.store_mode = this.rha_bill_id ? false : true;
    this.user_view = this.login.role_id != 9 ? true : false;
    this.getData();
  },
};
</script>